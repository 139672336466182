// 公司資料儲存還沒處理

<template>
  <div class="signup">
    <v-container>
      <v-row justify="center">
        <h3 class="signup__title hidden-sm-and-up">建立帳戶</h3>
      </v-row>
      <v-stepper alt-labels v-model="currentStep">
        <v-row justify="center">
          <h3 class="signup__title hidden-sm-and-down">建立帳戶</h3>
        </v-row>
        <v-stepper-header>
          <v-stepper-step :complete="currentStep > 1" step="1"> 條款同意 </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="currentStep > 2" step="2"> 加入信息填寫 </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="currentStep > 3" step="3"> 基本資料填寫 </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="currentStep > 4" step="4"> 註冊完成 </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <!-- 步驟一(條款同意)--Start -->
          <v-stepper-content step="1">
            <v-card class="pa-5" outlined>
              <p>
                喜來麗國際股份有限公司極為重視個人資料之安全與隱私，本公司所取得之資料均由您主動提供，且本公司會予以保密。
                <br />
                <br />
                就您提供之資料，我們將用於提供給您更好的服務。我們將使用您的個人資料於下列之目的:回應您對特定健康資訊、產品、服務之要求；為您提供特定產品或服務之個人化服務;與您聯絡，以及其他您已經同意之用途範圍。
                <br />
                <br />
                在未獲得您許可之情況下，我們不會將您的姓名、住址、電子郵件信箱或任何其他個人資料轉交給第三方，除非該第三人是本公司之下包商或代理人，協助或代理本公司提供產品或服務給您。
                <br />
                <br />
                但也提醒您；請勿在網路上公開透露您的個人資料，因該資料有可能會被他人蒐集和使用，特別是在網路上公開的發言場合。
                <br />
                <br />
                一、隱私權保護政策的適用範圍
                <br />
                隱私權保護政策內容，包括喜來麗國際股份有限公司如何處理在您使用網站服務時收集到的個人識別資料。隱私權保護政策不適用於本網站以外的相關連結網站，也不適用於非本網站所委託或參與管理的人員。隱私權保護政策內容，包括喜來麗國際股份有限公司如何處理在您使用網站服務時收集到的個人識喜來麗國際股份有限公司極為重視個人資料之安全與隱私，本公司所取得之資料均由您主動提供，且本公司會予以保密。
                <br />
                <br />
              </p>
            </v-card>
            <v-checkbox v-model="agreePrivacy">
              <template v-slot:label>
                <div>我同意網站服務條款及隱私政策</div>
              </template>
            </v-checkbox>
            <v-row justify="center" justify-md="end">
              <v-col cols="12" md="4">
                <v-btn block color="primary" @click="checkAgreeStatus()"> 下一步 </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <!-- 步驟一(條款同意)--Start -->

          <!-- 步驟二(加入信息填寫)--Start -->
          <v-stepper-content step="2" class="pa-0">
            <v-card class="pa-5" outlined>
              <v-form ref="singnupForm1" v-model="formValid" class="pa-0">
                <v-row justify="center">
                  <!-- 推薦人 -->
                  <v-col cols="12" md="8">
                    <v-card class="pa-5">
                      <v-row justify-md="space-between">
                        <v-col cols="12" md="6">
                          <h3>推薦人驗證</h3>
                        </v-col>
                        <v-col cols="12" md="4" class="px-0">
                          <v-alert v-if="!introducerStatus" outlined type="error" class="py-0 text-center">
                            尚未驗證
                          </v-alert>
                          <v-alert v-if="introducerStatus" outlined type="success" class="py-0 text-center">
                            完成驗證
                          </v-alert>
                        </v-col>
                      </v-row>
                      <label for="introducerID">推薦人驗證*</label>
                      <v-text-field
                        v-model="introducerID"
                        name="introducerID"
                        id="introducerID"
                        placeholder="請輸入您的推薦人編號"
                        class="pt-2"
                        :rules="rules.required"
                      ></v-text-field>
                      <!-- <label for="introducerName">推薦人姓名*</label>
                      <v-text-field
                        v-model="introducerName"
                        name="introducerName"
                        id="introducerName"
                        placeholder="請輸入您的推薦人姓名"
                        class="pt-2"
                        :rules="rules.required"
                      ></v-text-field> -->
                      <v-btn large color="primary" width="100%" @click="checkIntroducer()"> 驗證 </v-btn>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <!-- Email驗證 -->
                  <v-col cols="12" md="4">
                    <v-card class="pa-5">
                      <v-row justify-md="space-between">
                        <v-col cols="6" justify="space-between">
                          <h3>Email驗證</h3>
                        </v-col>
                        <v-col cols="12" md="4" class="px-0">
                          <v-alert v-if="!emailStatus" outlined type="error" class="py-0 text-center">
                            尚未驗證
                          </v-alert>
                          <v-alert v-if="emailStatus" outlined type="success" class="py-0 text-center">
                            驗證完成
                          </v-alert>
                        </v-col>
                      </v-row>
                      <label for="emailAddress">電子郵件地址*</label>
                      <v-text-field
                        v-model="emailAddress"
                        :rules="rules.emailRules"
                        name="emailAddress"
                        id="emailAddress"
                        placeholder="請輸入您的電子郵件地址"
                        class="pt-2"
                      ></v-text-field>
                      <v-btn large color="primary" width="100%" :disabled="emailAlert" @click="getEmailCodeWIthC()">
                        {{ getEmailCodeText }}
                      </v-btn>
                      <p v-if="emailAlert" class="red--text">驗證碼信件會在幾分鐘內收到請耐心等候</p>
                      <label for="emailCode">請輸入驗證碼</label>
                      <v-text-field
                        v-model="emailCode"
                        name="emailCode"
                        id="emailCode"
                        placeholder="請輸入您的Email驗證碼"
                        class="pt-2"
                      ></v-text-field>
                      <v-btn large color="primary" width="100%" @click="checkEmailCode()"> 驗證 </v-btn>
                    </v-card>
                  </v-col>

                  <!-- 登入資訊 -->
                  <v-col cols="12" md="4">
                    <v-card class="pa-5">
                      <v-row>
                        <v-col>
                          <h3>登入資訊</h3>
                        </v-col>
                      </v-row>
                      <label for="userName">會員編號</label>
                      <v-text-field
                        v-model="userName"
                        name="userName"
                        id="userName"
                        placeholder="此為系統產生 "
                        class="pt-2"
                        readonly
                      ></v-text-field>
                      <label for="userPassWord">密碼*</label>
                      <v-text-field
                        v-model="userPassWord"
                        :rules="rules.passwordRules"
                        name="userPassWord"
                        id="userPassWord"
                        placeholder="請輸入至少6位數的密碼"
                        class="pt-2"
                        type="password"
                      ></v-text-field>
                      <label for="userPassWordDouble">密碼確認*</label>
                      <v-text-field
                        v-model="userPassWordDouble"
                        :rules="rules.passwordCheck"
                        name="userPassWordDouble"
                        id="userPassWordDouble"
                        placeholder="請再次輸入您的密碼"
                        class="pt-2"
                        type="password"
                      ></v-text-field>
                    </v-card>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>

            <v-row justify="center" justify-md="end">
              <v-col cols="12" md="4">
                <v-btn block color="primary" @click="checkStep2()"> 下一步 </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <!-- 步驟二(加入信息填寫)--End -->

          <!-- 步驟三(基本資料填寫)--Start -->
          <v-stepper-content step="3">
            <v-card class="pa-5" outlined>
              <v-radio-group v-model="accountType" row mandatory :rules="rules.required">
                <v-radio label="個人名義" value="0"></v-radio>
                <v-radio label="公司名義" value="1"></v-radio>
              </v-radio-group>
              <v-form ref="singnupForm2" v-model="formValid" v-show="accountType == 0">
                <v-row justify="center">
                  <!-- 基本資料 -->
                  <v-col cols="12" md="4">
                    <v-card class="pa-5">
                      <v-row>
                        <v-col cols="6">
                          <h3>基本資料</h3>
                        </v-col>
                      </v-row>
                      <label for="userFullName">姓名*</label>
                      <v-text-field
                        v-model="userFullName"
                        name="userFullName"
                        placeholder="請輸入您的姓名"
                        class="pt-2"
                        :rules="rules.name"
                      ></v-text-field>
                      <label for="userID">身分證*</label>
                      <v-text-field
                        v-model="userID"
                        name="userID"
                        placeholder="請輸入您的身分證"
                        class="pt-2"
                        :rules="rules.userID"
                      ></v-text-field>
                      <label>生日*</label>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="userBirthday"
                            placeholder="請選擇您的生日"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="rules.required"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="userBirthday"
                          :active-picker.sync="activePicker"
                          :max="new Date().toISOString().substr(0, 10)"
                          min="1950-01-01"
                          @change="save"
                        ></v-date-picker>
                      </v-menu>
                      <label for="gender">性別*</label>
                      <v-radio-group v-model="gender" row :rules="rules.required">
                        <v-radio label="男" value="1"></v-radio>
                        <v-radio label="女" value="0"></v-radio>
                      </v-radio-group>
                      <label>戶籍地址*</label>
                      <v-row align="center">
                        <v-col cols="6" class="pb-0">
                          <v-select
                            v-if="cityName"
                            item-text="name"
                            :items="cityName"
                            v-model="userCity"
                            placeholder="請選擇"
                            filled
                            dense
                            :rules="rules.required"
                          ></v-select>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                          <v-select
                            v-if="userCity"
                            item-text="name"
                            :items="cityArea"
                            v-model="userArea"
                            placeholder="請選擇"
                            filled
                            dense
                            :rules="rules.required"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" class="py-3">
                          <v-text-field
                            v-model="userAddress"
                            name="userAddress"
                            placeholder="請輸入您的地址"
                            class="pt-2"
                            :rules="rules.required"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <!-- 聯絡資訊 -->
                  <v-col cols="12" md="4">
                    <v-card class="pa-5">
                      <v-row>
                        <v-col>
                          <h3>聯絡資訊</h3>
                        </v-col>
                      </v-row>
                      <label for="userPhoneNumber">電話</label>
                      <v-text-field
                        v-model="userPhoneNumber"
                        name="userPhoneNumber"
                        placeholder="請輸入您的電話號碼"
                        class="pt-2"
                        :rules="rules.phoneNumber"
                      ></v-text-field>
                      <label>通訊地址</label>
                      <v-row align="center">
                        <v-col cols="6" class="pb-0">
                          <v-select
                            v-if="cityName"
                            item-text="name"
                            :items="cityName"
                            v-model="userContactCity"
                            placeholder="請選擇"
                            filled
                            dense
                          ></v-select>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                          <v-select
                            v-if="userContactCity"
                            item-text="name"
                            :items="cityArea2"
                            v-model="userContactArea"
                            placeholder="請選擇"
                            filled
                            dense
                          ></v-select>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <v-text-field
                            v-model="userContactAddress"
                            name="userContactAddress"
                            placeholder="請輸入您的地址"
                            class="pt-2"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-btn large color="primary" width="100%" @click="sameAddress()"> 同戶籍地址 </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                    <!-- Google驗證資訊 -->
                    <!-- <v-card class="px-2 mt-5">
											<v-row justify="center">
												<h3>Google驗證資訊</h3>
												<v-col
												cols="12"
												class="g-recaptcha"
												data-sitekey="6LfLorsZAAAAADDMY12LZYivgiPTIJfilc1tM6qS"
												></v-col>
											</v-row>
										</v-card> -->
                  </v-col>
                </v-row>
              </v-form>
              <v-form ref="singnupForm3" v-model="formValid3" v-show="accountType == 1">
                <v-row justify="center">
                  <!-- 公司名義申請 -->
                  <v-col cols="12" md="4">
                    <v-card class="pa-5">
                      <v-row>
                        <v-col>
                          <h3>公司資料</h3>
                          <p class="memo">備註: 請填寫完整公司資料</p>
                        </v-col>
                      </v-row>
                      <label for="companyName">公司名稱*</label>
                      <v-text-field
                        v-model="companyName"
                        name="companyName"
                        id="companyName"
                        placeholder="請輸入您的公司名稱"
                        :rules="rules.required"
                        class="pt-2"
                      ></v-text-field>
                      <label for="companyTaxId">公司統編*</label>
                      <v-text-field
                        v-model="companyTaxId"
                        name="companyTaxId"
                        id="companyTaxId"
                        placeholder="請輸入您的公司統編"
                        :rules="rules.taxId"
                        class="pt-2"
                      ></v-text-field>
                      <label for="companyOwner">公司負責人姓名*</label>
                      <v-text-field
                        v-model="companyOwner"
                        name="companyOwner"
                        id="companyOwner"
                        placeholder="請輸入您的公司負責人姓名"
                        :rules="rules.name"
                        class="pt-2"
                      ></v-text-field>
                      <label for="ownerId">負責人身分證*</label>
                      <v-text-field
                        v-model="ownerId"
                        name="ownerId"
                        id="ownerId"
                        placeholder="請輸入您的負責人身分證"
                        :rules="rules.required"
                        class="pt-2"
                      ></v-text-field>
                      <label for="ownerBirthday">生日*</label>
                      <v-menu
                        ref="menu2"
                        v-model="ownerMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="ownerBirthday"
                            placeholder="請輸入您的負責人生日"
                            readonly
                            v-bind="attrs"
                            :rules="rules.required"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          :active-picker.sync="activePicker2"
                          v-model="ownerBirthday"
                          :max="new Date().toISOString().substr(0, 10)"
                          min="1950-01-01"
                          @change="save2"
                        ></v-date-picker>
                      </v-menu>
                      <label for="ownerGender">性別*</label>
                      <v-radio-group v-model="ownerGender" row :rules="rules.required">
                        <v-radio label="男" value="1"></v-radio>
                        <v-radio label="女" value="0"></v-radio>
                      </v-radio-group>
                      <label>戶籍地址*</label>
                      <v-row align="center">
                        <v-col cols="6" class="pb-0">
                          <v-select
                            v-if="cityName"
                            item-text="name"
                            :items="cityName"
                            v-model="companyCity"
                            placeholder="請選擇"
                            filled
                            :rules="rules.required"
                            dense
                          ></v-select>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                          <v-select
                            v-if="companyCity"
                            item-text="name"
                            :items="cityArea3"
                            v-model="companyArea"
                            placeholder="請選擇"
                            filled
                            :rules="rules.required"
                            dense
                          ></v-select>
                        </v-col>
                        <v-col cols="12" class="py-3">
                          <v-text-field
                            v-model="companyAddress"
                            name="companyAddress"
                            id="companyAddress"
                            placeholder="請輸入您的公司地址"
                            :rules="rules.required"
                            class="pt-2"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <!-- 聯絡資訊 -->
                  <v-col cols="12" md="4">
                    <v-card class="pa-5">
                      <v-row>
                        <v-col>
                          <h3>聯絡資訊</h3>
                        </v-col>
                      </v-row>
                      <label for="userPhoneNumber">電話</label>
                      <v-text-field
                        v-model="userPhoneNumber"
                        name="userPhoneNumber"
                        placeholder="請輸入您的電話號碼"
                        class="pt-2"
                        :rules="rules.phoneNumber"
                      ></v-text-field>
                      <label>通訊地址</label>
                      <v-row align="center">
                        <v-col cols="6" class="pb-0">
                          <v-select
                            v-if="cityName"
                            item-text="name"
                            :items="cityName"
                            v-model="userContactCity"
                            placeholder="請選擇"
                            filled
                            dense
                          ></v-select>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                          <v-select
                            v-if="userContactCity"
                            item-text="name"
                            :items="cityArea2"
                            v-model="userContactArea"
                            placeholder="請選擇"
                            filled
                            dense
                          ></v-select>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <v-text-field
                            v-model="userContactAddress"
                            name="userContactAddress"
                            id="userContactAddress"
                            placeholder="請輸入您的地址"
                            class="pt-2"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-btn large color="primary" width="100%" @click="sameAddress()"> 同戶籍地址 </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>

            <v-row justify="center" justify-md="end">
              <v-col cols="12" md="4">
                <v-btn block color="primary" @click="sendRegisterWithC()"> 下一步 </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <!-- 步驟三(基本資料填寫)--End -->

          <!-- 步驟四(註冊完成)--Start -->
          <v-stepper-content step="4">
            <v-card class="pa-5" outlined>
              <v-row justify="center">
                <h1>恭喜您，註冊成功！</h1>
              </v-row>
              <v-row justify="center">
                <h2>您的帳號為: {{ userName }}</h2>
              </v-row>
              <v-row justify="center">
                <p>10秒後自動挑轉至登入頁面，</p>
                <p>
                  若無跳轉
                  <router-link to="login" style="color: red; text-decoration: none"> 請點此至登入頁面 </router-link>
                </p>
              </v-row>
            </v-card>
          </v-stepper-content>
          <!-- 步驟四(註冊完成)--End -->
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </div>
</template>

<script>
import cityName from '@/assets/taiwan_districts.json';
import { signupPublicAPI } from '@/api/signup/api';
import { showLoading, hideLoading } from '@/utils/loading';
import axios from 'axios';
export default {
  data() {
    return {
      rules: {
        required: [(value) => !!value || '必填欄位'],
        name: [(v) => !!v || '必填欄位', (v) => /^[\u4e00-\u9fa5]+$/.test(v) || '只能輸入中文'],
        emailRules: [(v) => /.+@.+/.test(v) || '請輸入正確電子信箱'],
        passwordRules: [(v) => (v && v.length >= 6) || '密碼必須至少6位數'],
        passwordCheck: [(v) => this.userPassWord === this.userPassWordDouble || '密碼不匹配'],
        userID: [(v) => (v && v.length >= 10 && /^[A-Za-z]/.test(v)) || '請輸入正確的身分證字號'],
        phoneNumber: [(v) => (v && v.length >= 10) || '請輸入正確的手機號碼'],
        taxId: [(v) => (v && v.length >= 8) || '請輸入正確的統編號碼'],
      },
      getEmailCodeText: '發送驗證碼',
      activePicker: '',
      activePicker2: '',
      currentStep: 1,
      agreePrivacy: false,
      introducerID: '',
      introducerName: '',
      introducerTeamName: '',
      introducerStatus: false,
      previousPersonID: '',
      previousPersonInfo: {},
      previousPersonPositionList: [],
      previousPersonPosition: '',
      previousStatus: false,
      emailAddress: '',
      emailCode: '',
      fromEmail: '', //getEmailCode 回傳的email
      emailAlert: false,
      emailStatus: false,
      userName: '',
      userPassWord: '',
      userPassWordDouble: '',
      formValid: false,
      formValid3: false,

      //先讓email過關
      checkcode: '',

      accountType: 0,
      userFullName: '',
      userID: '',
      userBirthday: '',
      gender: Number,
      userCity: '',
      userArea: '',
      userAddress: '',
      userPhoneNumber: '',
      userContactCity: '',
      userContactArea: '',
      userContactAddress: '',
      companyName: '',
      companyTaxId: '',
      companyOwner: '',
      ownerId: '',
      ownerBirthday: '',
      ownerGender: Number,
      companyCity: '',
      companyArea: '',
      companyAddress: '',
      menu: false,
      ownerMenu: false,
      cityName,
      cityArea: null,
      cityArea2: null,
      cityArea3: null,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
    menu2(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker2 = 'YEAR'));
    },
    userCity(val) {
      this.cityArea = cityName.find((city) => city.name === this.userCity).districts;
    },
    userContactCity(val) {
      this.cityArea2 = cityName.find((city) => city.name === this.userContactCity).districts;
    },
    companyCity(val) {
      this.cityArea3 = cityName.find((city) => city.name === this.companyCity).districts;
    },
  },
  methods: {
    checkAgreeStatus() {
      if (!this.agreePrivacy) {
        this.$Message.error('若您沒有同意服務條款及隱私政策，將無法進行下一步');
      } else {
        this.currentStep = 2;
      }
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    save2(date) {
      this.$refs.menu2.save(date);
    },
    // 驗證介紹人
    checkIntroducer() {
      if (this.introducerID != '') {
        showLoading();
        axios
          .post('http://web.hilary.com.tw:8085/getapi?type=CHECKINTRODUCER', {
            introducer_id: this.introducerID,
          })
          .then((res) => {
            if (res.status == 200 && res.data.success == true) {
              this.introducerStatus = true;
              hideLoading();
            }
          })
          .catch((e) => {
            this.$Message.error(e);
            hideLoading();
          });
      }
    },
    // checkIntroducer() {
    //   if (this.introducerID != "" && this.introducerName != "") {
    //     signupPublicAPI
    //       .check_introducer({
    //         introducer_id: this.introducerID,
    //         introducer_name: this.introducerName,
    //       })
    //       .then((res) => {
    //         if (res.data.code == 200 && res.data.data.status == true) {
    //           this.introducerStatus = true;
    //           if (this.emailStatus) {
    //             signupPublicAPI.get_member_id().then((res) => {
    //               this.userName = res.data.data.member_id;
    //             });
    //           }
    //         }
    //       });
    //   }
    // },
    checkPrevious() {
      if (this.previousPersonID != '') {
        this.previousPersonPositionList = [];
        this.previousPersonPosition = '';
        signupPublicAPI.check_previous({ previous_id: this.previousPersonID }).then((res) => {
          this.previousPersonInfo = res.data.data;
          if (res.data.data.left && res.data.data.right) {
            return this.$Message.error('此安置人已無安置區，請選擇其他安置人');
          }
          if (!res.data.data.left) {
            this.previousPersonPositionList.push({
              key: 'left',
              position: res.data.data.AbsolutePosition + '1',
            });
          }
          if (!res.data.data.right) {
            this.previousPersonPositionList.push({
              key: 'right',
              position: res.data.data.AbsolutePosition + '2',
            });
          }
        });
      }
    },
    // 獲取Email驗證碼
    getEmailCodeWIthC() {
      if (this.emailAddress != '') {
        showLoading();
        axios
          .post('http://web.hilary.com.tw:8085/getapi?type=email', {
            user_email: this.emailAddress,
          })
          .then((res) => {
            if (res.status == 200 && res.data.success == true) {
              this.emailAlert = true;
              //重新獲得驗證碼倒計時
              let n = 180;
              this.getEmailCodeText = '重新獲得驗證碼' + n + 's';
              const run = setInterval(() => {
                n = n - 1;
                if (n < 0) {
                  clearInterval(run);
                }
                this.getEmailCodeText = '重新獲得驗證碼' + n + 's';
                if (this.getEmailCodeText < '重新獲得驗證碼' + 0 + 's') {
                  this.emailAlert = false;
                  this.getEmailCodeText = '重新獲得驗證碼';
                }
              }, 1000);
              this.fromEmail = res.data.from_email;
              this.checkcode = res.data.key;
              hideLoading();
            }
          })
          .catch((e) => {
            this.$Message.error(e);
            hideLoading();
          });
      }
    },
    //原本調用的API
    // getEmailCode() {
    //   if (this.emailAddress != "") {
    //     signupPublicAPI
    //       .get_email_code({ user_email: this.emailAddress })
    //       .then((res) => {
    //         if (res.data.code == 200 && res.data.data.status == true) {
    //           this.emailAlert = true;
    //           this.checkcode = res.data.data.checkcode;
    //         }
    //       });
    //   }
    // },
    // 驗證Email驗證碼
    checkEmailCode() {
      if (this.emailCode != '') {
        if (this.emailCode == this.checkcode && this.emailAddress == this.fromEmail) {
          this.emailStatus = true;
          if (this.introducerStatus) {
            signupPublicAPI.get_member_id().then((res) => {
              this.userName = res.data.data.member_id;
            });
          }
        }
        // signupPublicAPI
        //   .check_email_code({
        //     user_email: this.emailAddress,
        //     email_code: this.emailCode
        //   })
        //   .then(res => {
        //     if (res.data.code == 200 && res.data.data.status == true) {
        //       this.emailStatus = true;
        //       if (this.introducerStatus) {
        //         signupPublicAPI.get_member_id().then(res => {
        //           this.userName = res.data.data.member_id;
        //         });
        //       }
        //     }
        //   });
      }
    },
    checkStep2() {
      if (this.$refs.singnupForm1.validate() && this.emailStatus && this.introducerStatus) {
        this.currentStep = 3;
      }
    },
    sameAddress() {
      if (this.accountType == 0) {
        this.userContactCity = this.userCity;
        this.userContactArea = this.userArea;
        this.userContactAddress = this.userAddress;
      } else {
        this.userContactCity = this.companyCity;
        this.userContactArea = this.companyArea;
        this.userContactAddress = this.companyAddress;
      }
    },
    sendRegisterWithC() {
      // if (this.$refs.singnupForm2.validate()) {
      if (this.accountType == '0') {
        if (!this.$refs.singnupForm2.validate()) {
          this.$Message.error('請輸入完整資料');
          return;
        }
        showLoading();
        axios
          .post('http://web.hilary.com.tw:8085/getapi?type=save1', {
            introducer_id: this.introducerID,
            introducer_name: this.introducerName,
            previous_id: this.previousPersonID,
            // previous_name: this.previousPersonName,
            email: this.emailAddress,
            member_id: this.userName,
            password: this.userPassWord,
            account_type: this.accountType,
            name: this.userFullName,
            id_card: this.userID,
            birthday: this.userBirthday,
            gender: this.gender,
            address: this.userCity + this.userArea + this.userAddress,
            phone: this.userPhoneNumber,
            contact_address: this.userContactCity + this.userContactArea + this.userContactAddress,
          })
          .then((res) => {
            if (res.status == 200) {
              hideLoading();
              this.backtoLogin();
            } else {
              console.log('發生錯誤');
              hideLoading();
              return;
            }
          })
          .catch((e) => {
            hideLoading();
            this.$Message.error(e);
          });
      } else {
        if (!this.$refs.singnupForm3.validate()) {
          this.$Message.error('請輸入完整資料');
          return;
        }
        showLoading();
        axios
          .post('http://web.hilary.com.tw:8085/getapi?type=save2', {
            introducer_id: this.introducerID,
            introducer_name: this.introducerName,
            previous_id: this.previousPersonID,
            // previous_name: this.previousPersonName,
            email: this.emailAddress,
            member_id: this.userName,
            password: this.userPassWord,
            account_type: this.accountType,
            //name: this.userFullName,
            //id_card: this.userID,
            //birthday: this.userBirthday,
            //address: this.userCity + this.userArea + this.userAddress,
            phone: this.userPhoneNumber,
            contact_address: this.userContactCity + this.userContactArea + this.userContactAddress,
            company: this.companyName,
            tax_id: this.companyTaxId,
            owner: this.companyOwner,
            owner_id: this.ownerId,
            owner_birthday: this.ownerBirthday,
            gender: this.ownerGender,
            company_address: this.companyCity + this.companyArea + this.companyAddress,
          })
          .then((res) => {
            if (res.status == 200) {
              hideLoading();
              this.backtoLogin();
            } else {
              hideLoading();
              console.log('發生錯誤');
              return;
            }
          })
          .catch((e) => {
            hideLoading();
            this.$Message.error(e);
          });
      }
      //寄送email告知會員編號
      this.sendEmailWIthC();
      // } else {
      //   this.backtoLogin();
      //   this.$Message.error("註冊錯誤");
      //   return;
      // }
    },
    sendEmailWIthC() {
      if (this.emailAddress != '') {
        showLoading();
        axios
          .post('http://web.hilary.com.tw:8085/getapi?type=email2', {
            user_email: this.emailAddress,
            user_id: this.userName,
          })
          .then((res) => {
            if (res.status == 200 && res.data.success == true) {
              this.$Message.success('註冊完成!已寄送email至您的郵箱,請到電子郵箱確認.');
              hideLoading();
            }
          })
          .catch((e) => {
            hideLoading();
            this.$Message.error(e);
          });
      }
    },
    sendRegister() {
      if (this.$refs.singnupForm2.validate()) {
        if (this.accountType == '0') {
          signupPublicAPI.post_register({
            introducer_id: this.introducerID,
            introducer_name: this.introducerName,
            previous_id: this.previousPersonID,
            previous_name: this.previousPersonName,
            email: this.emailAddress,
            member_id: this.userName,
            password: this.userPassWord,
            account_type: this.accountType,
            name: this.userFullName,
            id_card: this.userID,
            birthday: this.userBirthday,
            address: this.userCity + this.userArea + this.userAddress,
            phone: this.userPhoneNumber,
            contact_address: this.userContactCity + this.userContactArea + this.userContactAddress,
          });
        } else {
          signupPublicAPI
            .post_register(
              {
                introducer_id: this.introducerID,
                introducer_name: this.introducerName,
                previous_id: this.previousPersonID,
                previous_name: this.previousPersonName,
                email: this.emailAddress,
                member_id: this.userName,
                password: this.userPassWord,
                account_type: this.accountType,
                name: this.userFullName,
                id_card: this.userID,
                birthday: this.userBirthday,
                address: this.userCity + this.userArea + this.userAddress,
                phone: this.userPhoneNumber,
                contact_address: this.userContactCity + this.userContactArea + this.userContactAddress,
              },
              {
                company: this.companyName,
                tax_id: this.companyTaxId,
                owner: this.companyOwner,
                owner_id: this.ownerId,
                owner_birthday: this.ownerBirthday,
                gender: this.ownerGender,
                company_address: this.companyCity + this.companyArea + this.companyAddress,
              }
            )
            .then((res) => {
              if (res.data.code == 200) {
                console.log(res.data.code);
                this.backtoLogin();
              } else {
                console.log(res.data.code);
                return;
              }
            });
        }
        this.backtoLogin();
      }
    },
    backtoLogin() {
      this.currentStep = 4;
      setTimeout(() => {
        this.$router.push('/login');
      }, 10000);
    },
  },
};
</script>

<style lang="scss">
.signup {
  background-color: var(--background-color);
  padding: 5%;
}
.signup__title {
  margin: 20px 0px;
}
.signup form {
  padding: 20px;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 6px;
}
.signup form h3 {
  margin-bottom: 10px;
}
.signup .v-stepper,
.signup .v-stepper__header,
.signup .v-stepper__content {
  box-shadow: unset !important;
}
// 條款同意外框線保留
.signup .v-stepper__items .v-stepper__content:nth-child(1) .theme--light.v-sheet--outlined {
  border: thin solid rgba(0, 0, 0, 0.12);
}
.signup .v-stepper__items .v-stepper__content .theme--light.v-sheet--outlined {
  border: unset;
}
@media (min-width: 768px) {
  .signup {
    padding: 10vh 5vw;
  }
}
.memo {
  font: {
    size: 10px;
    weight: bold;
  }
  color: red;
}
</style>
